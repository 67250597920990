import React, {
  createContext, useState, useCallback, useMemo, Suspense, startTransition,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './homePage/header/Header';
import Footer from './homePage/footer/Footer';
import ScreenLoader from './common/components/ScreenLoader';

export const FallbackContext = createContext({
  updateFallback: () => {},
});

const FabllbackProvider = ({ children }) => {
  const location = useLocation();
  const path = location?.pathname;
  const [fallback, setFallback] = useState(<ScreenLoader />);
  // const platform = process.env.REACT_APP_PLATFORM;
  const disablePasswordProtection = process.env.REACT_APP_DISABLE_PASSWORD_PROTECTION;
  const mapFullScreen = useSelector((state) => state.session.mapFullScreen);
  let isAuthPage = false;
  const authPages = ['/login', '/register', '/reset-password', '/password-protection', '/reset-password-link', '/verification', 'verified-password-reset', '/qr-code', '/activate-pom', '/docs'];
  if (authPages.includes(path)) {
    isAuthPage = true;
  }

  const passwordProtection = window.localStorage.getItem('passwordProtection');
  const notShowHeaderPages = ['/', '/about-us', '/contact-us'];
  const showHeader = !notShowHeaderPages.includes(path) && !mapFullScreen && !isAuthPage && (passwordProtection || disablePasswordProtection === 'true');

  const updateFallback = useCallback(() => {
    // Wrap the state update with startTransition
    startTransition(() => {
      setFallback(<ScreenLoader />);
    });
  }, []);

  const contextValue = useMemo(() => ({ updateFallback }), [updateFallback]);

  return (
    <FallbackContext.Provider value={contextValue}>
      <Suspense fallback={fallback}>
        {(children && showHeader) && (
        <Header />
        )}
        {children}
        {!mapFullScreen && children && !isAuthPage && (passwordProtection || disablePasswordProtection === 'true') && (
        <Footer />
        )}
      </Suspense>
    </FallbackContext.Provider>
  );
};

export default FabllbackProvider;
